import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { SubdivisionAccountsService } from '@app/services/subdivision-accounts.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class SubdivisionAccountsStoreEffects {
  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      flatMap((action: featureActions.LoadRequestAction) => {
        return this.dataService.load(action.payload.reservation_id).pipe(
          map(
            ({ data, meta }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      flatMap((action: featureActions.UpdateRequestAction) => {
        const { reservation_id, data, onSuccess, onFailure } = action.payload;
        return this.dataService.update(reservation_id, data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('subdivision_account'),
              }),
              type: 'success',
            });

            if (onSuccess) {
              onSuccess();
            }

            return new featureActions.UpdateSuccessAction({
              items: action.payload.data,
            });
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );
  constructor(
    private dataService: SubdivisionAccountsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}
}
