import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ChannelsCheckInResponse } from '../../models/responses/channels-check-in.response';
import { ChannelsCheckInService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ChannelsCheckInStoreEffects {
  constructor(
    private dataService: ChannelsCheckInService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translateService: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ reservationId }) =>
        this.dataService.load(reservationId).pipe(
          map(({ data }: IResponseSuccess<ChannelsCheckInResponse>) => {
            return fromActions.loadSuccess({ data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ reservationId, payload, onSuccess, onError }) =>
        this.dataService.update(reservationId, payload).pipe(
          map(({ data }: IResponseSuccess<ChannelsCheckInResponse>) => {
            onSuccess();
            return fromActions.updateSuccess({ status: payload.action });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            onError();
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  activation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activationRequest),
      switchMap(({ payload, onSuccess, onError }) =>
        this.dataService.activation(payload).pipe(
          map(() => {
            this.notifications.updateSuccess('check_in_online');
            onSuccess();
            return fromActions.activationSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            onError();
            return of(fromActions.activationFailure(error));
          }),
        ),
      ),
    ),
  );
}
