import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Invoice } from '../../models';

import { State } from './state';

/**
 * State selector
 */
export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('reservationDetailsInvoicesStore');

/**
 * Errors selector
 */
export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  (state: State) => state.error,
);

/**
 * Invoices selector
 */
export const selectInvoices: MemoizedSelector<object, Invoice[]> =
  createSelector(selectState, (state: State) => state.invoices);

/**
 * Reservation invoices loading
 */
export const selectReservationInvoicesLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectState, (state: State): boolean => state.isLoading);

/**
 * END LOADING SELECTORS
 */
