import { createAction, props } from '@ngrx/store';

import { ReservationCancellationPolicyExtra } from '../../models';

import * as fromState from './state';

export const loadCalendarRequest = createAction(
  '[Reservation Details Cancellation Policy] Load Calendar Request',
  props<{
    reservation_id: number;
    reference_date?: Date;
    reservation_accommodation_id?: number[];
  }>(),
);
export const loadCalendarSuccess = createAction(
  '[Reservation Details Cancellation Policy] Load Calendar Success',
  props<{ data: Partial<fromState.State> }>(),
);
export const loadCalendarFailure = createAction(
  '[Reservation Details Cancellation Policy] Load Calendar Failure',
  props<{ error: any }>(),
);

export const loadExtrasRequest = createAction(
  '[Reservation Details Cancellation Policy] Load Extras Request',
  props<{
    reservationId: number;
    days: string[];
    reservationAccommodationIds?: number[];
  }>(),
);
export const loadExtrasSuccess = createAction(
  '[Reservation Details Cancellation Policy] Load Extras Success',
  props<{ extras: ReservationCancellationPolicyExtra[] }>(),
);
export const loadExtrasFailure = createAction(
  '[Reservation Details Cancellation Policy] Load Extras Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Reservation Details Cancellation Policy] Reset State',
);
