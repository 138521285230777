import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GuestCardsStoreEffects } from './effects';
import { GuestCardsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('guestCardsStore', GuestCardsReducer),
    EffectsModule.forFeature([GuestCardsStoreEffects]),
  ],
})
export class GuestCardsStoreModule {}
