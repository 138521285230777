import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, flatMap, map } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ReservationDetailsInvoicesService } from '../../services/reservation-details-invoices.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ReservationDetailsInvoicesStoreEffects {
  constructor(
    private dataService: ReservationDetailsInvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  /** Load di tutte le fattre  */
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      flatMap(
        ({
          reservationId,
          reservationAccommodationId,
          skip_rejected_advances,
        }) =>
          this.dataService
            .load(
              reservationId,
              reservationAccommodationId,
              skip_rejected_advances,
            )
            .pipe(
              map((response: any) => {
                return fromActions.loadSuccess({ data: response.data });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.loadFailure(error));
              }),
            ),
      ),
    ),
  );

  /** Register payment fattura  */
  registerPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.registerPaymentRequest),
      flatMap(({ data }) =>
        this.dataService.registerPayment(data).pipe(
          map((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('invoice'),
              }),
              type: 'success',
            });
            return fromActions.registerPaymentSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.registerPaymentFailure(error));
          }),
        ),
      ),
    ),
  );

  /**
   * Generate bills
   */
  generateBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.generateBillsRequest),
      exhaustMap(({ data }) => {
        return this.dataService.generateBills(data).pipe(
          map(
            (response: IResponseSuccess<{ created_invoice_ids: number[] }>) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('bills'),
                  },
                ),
                type: 'success',
              });
              return fromActions.generateBillsSuccess({
                createdInvoiceIds: response.data.created_invoice_ids,
              });
            },
          ),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.generateBillsFailure({ error }));
          }),
        );
      }),
    ),
  );
}
