import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubdivisionAccountsService {
  constructor(private http: HttpClient) {}

  load(reservationId) {
    return this.http.get(`reservation/${reservationId}/payment_agreement`);
  }

  update(reservationId, data) {
    return this.http.post(
      `reservation/${reservationId}/payment_agreement`,
      data,
    );
  }
}
