import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>(
  'reservationDetailsCancellationPolicyStore',
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectExtrasLoading = createSelector(
  selectState,
  (state: State) => state.extrasLoading,
);

export const selectDays = createSelector(
  selectState,
  (state: State) => state.days,
);

export const selectTotals = createSelector(
  selectState,
  (state: State) => state.totals,
);

export const selectExtras = createSelector(
  selectState,
  (state: State) => state.extras,
);

export const selectPolicy = createSelector(
  selectState,
  (state: State) => state.policy,
);
