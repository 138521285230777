import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { GuestCardService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class GuestCardsStoreEffects {
  constructor(
    private dataService: GuestCardService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ data }) => {
        return this.dataService.load(data).pipe(
          map((response) => {
            return fromActions.loadSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ request }) => {
        return this.dataService.loadDetails(request).pipe(
          map((response) => {
            return fromActions.loadDetailsSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        );
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, onSuccess }) => {
        return this.dataService.create(request).pipe(
          effectHooks({ onSuccess }),
          map((response) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('card'),
              }),
              type: 'success',
            });
            return fromActions.createSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        );
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, onSuccess }) => {
        return this.dataService.update(request).pipe(
          effectHooks({ onSuccess }),
          map((response) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('card'),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id, reservation_id }) => {
        return this.dataService.delete({ id, reservation_id }).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('card'),
              }),
              type: 'success',
            });
            return fromActions.deleteSuccess({
              id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        );
      }),
    ),
  );
}
