import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadCalendarRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.loadCalendarSuccess, (state, { data }) => {
    return {
      ...state,
      ...data,
      loading: false,
    };
  }),
  on(fromActions.loadCalendarFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.loadExtrasRequest, (state) => ({
    ...state,
    extrasLoading: true,
    error: null,
  })),
  on(fromActions.loadExtrasSuccess, (state, { extras }) => {
    return {
      ...state,
      extras,
      extrasLoading: false,
    };
  }),
  on(fromActions.loadExtrasFailure, (state, { error }) => ({
    ...state,
    extrasLoading: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),
);

export function reservationDetailsCancellationPolicyReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
