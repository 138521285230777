import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { Invoice, IPaymentData } from '../../models';
import { PaymentsService } from '../../services/payments.service';
import { ReservationDetailsInvoicesService } from '../../services/reservation-details-invoices.service';

import * as fromActions from './actions';
import { NotificationService } from '../../ui/services/notification.service';
import { effectHooks } from '../../helpers';

@Injectable()
export class ReservationDetailsPaymentsStoreEffects {
  constructor(
    private paymentsService: PaymentsService,
    private invoicesService: ReservationDetailsInvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notification: NotificationService,
  ) {}
  /** Carico gli addebiti filtrati per categoria */
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ reservationId, reservationAccommodationIds }) =>
        combineLatest([
          this.paymentsService.load(reservationId, reservationAccommodationIds),
          this.invoicesService.load(
            reservationId,
            reservationAccommodationIds,
            0,
          ),
        ]).pipe(
          map(
            ([{ data: paymentsData }, { data: invoices }]: [
              IResponseSuccess<IPaymentData>,
              IResponseSuccess<Invoice[]>,
            ]) => {
              return fromActions.loadSuccess({ paymentsData, invoices });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  transfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.transferRequest),
      switchMap(
        ({
          reservationId,
          reservationPaymentIds,
          destinationReservationId,
          hooks,
        }) =>
          this.paymentsService
            .transfer({
              reservationId,
              reservationPaymentIds,
              destinationReservationId,
            })
            .pipe(
              effectHooks(hooks),
              map(() => {
                this.notification.done('payments_moved_success');
                return fromActions.transferSuccess();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.transferFailure(error));
              }),
            ),
      ),
    ),
  );

  restore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.restorePaymentRequest),
      switchMap(({ paymentId, reservationId, hooks }) =>
        this.paymentsService.restore({ paymentId, reservationId }).pipe(
          effectHooks(hooks),
          map(() => {
            this.notification.done('payments_moved_success');
            return fromActions.restorePaymentSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.restorePaymentFailure(error));
          }),
        ),
      ),
    ),
  );
}
