import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getEntities = (state: State) => state.entities;

export const getDetails = (state: State) => state.details;

export const selectState = createFeatureSelector<State>('guestCardsStore');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectDetails = createSelector(selectState, getDetails);

export const selectCardById = (id: number) =>
  createSelector(selectState, (state: State) => state.entities[id]);
