import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const selectState = createFeatureSelector<State>('invoicePrintSettings');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectData = createSelector(selectState, getData);
