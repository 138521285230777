import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GuestCardService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class GuestCardTypesStoreEffects {
  constructor(
    private dataService: GuestCardService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap((data) => {
        return this.dataService.loadCardTypes(data).pipe(
          map((response) => {
            return fromActions.loadSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
