import { Action, createReducer, on } from '@ngrx/store';

import { SdiStatus } from '../../config/sdi-status.config';
import {
  Invoice,
  IPaymentData,
  PaymentDetail,
  PaymentRow,
  ReservationPaymentsList,
  ReservationPaymentsStats,
} from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromActions.loadSuccess,
    (state, { invoices: invoicesFromApi, paymentsData }) => {
      const invoices = getInvoices(paymentsData, invoicesFromApi);
      const payments = getPayments(paymentsData, invoices.items);
      const stats = getStats(payments.items, invoices.items);

      return {
        ...state,
        loading: false,
        invoices,
        payments,
        stats,
      };
    },
  ),

  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),

  on(fromActions.transferRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fromActions.transferSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(fromActions.transferFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.restorePaymentRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(fromActions.restorePaymentFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.restorePaymentSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
);

export function reservationDetailsPaymentsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

// ------------ HELPERS ---------------

function getPayments(
  paymentsData: IPaymentData,
  invoices: Invoice[],
): ReservationPaymentsList<PaymentRow> {
  const { payments_emitted, payments_received } = paymentsData;

  const payments = [
    ...getPaymentsRows(payments_received).map((payment) => ({
      ...payment,
      type: 'in',
      amount: +payment.amount,
    })),
    ...getPaymentsRows(payments_emitted).map((payment) => ({
      ...payment,
      type: 'out',
      amount: +payment.amount * -1,
      notes: payment.expense_category_id
        ? payment.notes || payment.label
        : payment.notes,
    })),
  ];

  const items = [
    ...payments,
    ...getSuspendedInvoicesAsPaymentsRows(invoices),
  ].map((payment: PaymentRow) => ({
    ...payment,
    accountholder: getAccountholder(payment),
  }));

  return {
    items,
    totals: {
      in: +payments_received?.total || 0,
      out: +payments_emitted?.total || 0,
      total: (+payments_received?.total || 0) - (+payments_emitted?.total || 0),
    },
  };
}

function getInvoices(
  paymentsData: IPaymentData,
  invoices: Invoice[],
): ReservationPaymentsList<Invoice> {
  const { invoices: invoicesData } = paymentsData;

  const items = invoices
    .filter(
      ({ registered, status_sdi }) =>
        registered || status_sdi === SdiStatus.Rejected,
    )
    .map((invoice) => ({
      ...invoice,
      total:
        invoice.type === 'credit_note' ? +invoice.total * -1 : +invoice.total,
      ...(invoice.status === 'paid' && invoice.to_pay > 0
        ? {
            paid: invoice.paid + invoice.to_pay,
            to_pay: 0,
          }
        : {}),
    }));

  return {
    items,
    totals: {
      total: +invoicesData?.total || 0,
    },
  };
}

function getStats(
  payments: PaymentRow[],
  invoices: Invoice[],
): ReservationPaymentsStats {
  return {
    counters: getStatsCounters(payments, invoices),
  };
}

function getAccountholder(payment: PaymentRow): string {
  if (!payment.customer) {
    return '';
  }

  if (payment.company) {
    return payment.customer?.name || payment.customer?.surname;
  }

  return `${payment.customer.surname} ${payment.customer.name}`;
}

function getStatsCounters(payments: PaymentRow[], invoices: Invoice[]) {
  const invoicesCounter = invoices.filter(
    ({ type }) => type === 'invoice',
  ).length;

  const receiptsCounter = invoices.filter(
    ({ type }) => type === 'receipt',
  ).length;

  const depositsCounter = payments.filter(({ deposit }) => deposit).length;

  return {
    invoices: invoicesCounter,
    receipts: receiptsCounter,
    deposits: depositsCounter,
  };
}

function getPaymentsRows(payments: PaymentDetail): PaymentRow[] {
  return Object.values(payments?.details || {}).reduce(
    (rows, paymentMethod) => (rows = [...rows, ...paymentMethod?.rows]),
    [],
  );
}

function getSuspendedInvoicesAsPaymentsRows(invoices: Invoice[]): PaymentRow[] {
  return invoices
    .filter(
      (invoice) =>
        invoice.type !== 'credit_note' && invoice.status === 'suspended',
    )
    .map((invoice) => {
      const linkedDocuments = invoices.filter(
        (document) => invoice?.linked_documents?.indexOf(document.id) !== -1,
      );
      return {
        amount:
          invoice.to_pay -
          (linkedDocuments || []).reduce(
            (total, creditNote) => (total += creditNote.paid),
            0,
          ),
        customer: invoice.customer,
        company: !!invoice.company,
        deposit: false,
        notes: null,
        payment_method: null,
        payment_method_id: null,
        payment_method_name: null,
        property_pos: null,
        registration_date: null,
        reservation_payment_id: null,
        roomreservation_id: null,
        credit_card_data_count: false,
      };
    })
    .filter((invoice) => invoice.amount > 0);
}
