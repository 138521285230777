import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationDetailsCancellationPolicyStoreEffects } from './effects';
import { reservationDetailsCancellationPolicyReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'reservationDetailsCancellationPolicyStore',
      reservationDetailsCancellationPolicyReducer,
    ),
    EffectsModule.forFeature([
      ReservationDetailsCancellationPolicyStoreEffects,
    ]),
  ],
})
export class ReservationDetailsCancellationPolicyStoreModule {}
