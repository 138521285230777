import { createAction, props } from '@ngrx/store';

import { ChannelsCheckInRequest } from '../../models/requests/channels-check-in.request';
import {
  ChannelsCheckInActivation,
  ChannelsCheckInResponse,
  ChannelsCheckInStatusKeys,
} from '../../models/responses/channels-check-in.response';

export const loadRequest = createAction(
  '[Channels Check-In] Load Request',
  props<{ reservationId: number }>(),
);
export const loadSuccess = createAction(
  '[Channels Check-In] Load Success',
  props<{ data: ChannelsCheckInResponse }>(),
);
export const loadFailure = createAction(
  '[Channels Check-In] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Channels Check-In] Update Request',
  props<{
    reservationId: number;
    payload: ChannelsCheckInRequest;
    onSuccess: () => void;
    onError: () => void;
  }>(),
);
export const updateSuccess = createAction(
  '[Channels Check-In] Update Success',
  props<{ status: ChannelsCheckInStatusKeys }>(),
);
export const updateFailure = createAction(
  '[Channels Check-In] Update Failure',
  props<{ error: any }>(),
);

export const activationRequest = createAction(
  '[Channels Check-In] Activation Request',
  props<{
    payload: ChannelsCheckInActivation;
    onSuccess: () => void;
    onError: () => void;
  }>(),
);
export const activationSuccess = createAction(
  '[Channels Check-In] Activation Success',
);
export const activationFailure = createAction(
  '[Channels Check-In] Activation Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channels Check-In] Reset State');
