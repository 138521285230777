import { createAction, props } from '@ngrx/store';

import { IBillsComputation, Invoice, IRegisterPayment } from '../../models';

/**
 * L'azione loadInvoice elimina la fattura di una prenotazione
 */
export const loadRequest = createAction(
  '[Reservation Details Invoices v2] Load Request',
  props<{
    reservationId: number;
    reservationAccommodationId?: number[];
    skip_rejected_advances?: 0 | 1;
  }>(),
);
export const loadSuccess = createAction(
  '[Reservation Details Invoices v2] Load Success',
  props<{
    data: Invoice[];
  }>(),
);
export const loadFailure = createAction(
  '[Reservation Details Invoices v2] Load Failure',
  props<{ error: any }>(),
);

/**
 * Associa/Dissocia un pagamento
 */
export const registerPaymentRequest = createAction(
  '[Reservation Details Invoices v2] Register Payment Request',
  props<{
    data: IRegisterPayment;
  }>(),
);
export const registerPaymentSuccess = createAction(
  '[Reservation Details Invoices v2] Register Payment Success',
);
export const registerPaymentFailure = createAction(
  '[Reservation Details Invoices v2] Register Payment Failure',
  props<{ error: any }>(),
);

/**
 * Genera conti
 */
export const generateBillsRequest = createAction(
  '[Reservation Details Invoices v2] Generate Bills Request',
  props<{ data: IBillsComputation }>(),
);
export const generateBillsSuccess = createAction(
  '[Reservation Details Invoices v2] Generate Bills Success',
  props<{ createdInvoiceIds: number[] }>(),
);
export const generateBillsFailure = createAction(
  '[Reservation Details Invoices v2] Generate Bills Failure',
  props<{ error: any }>(),
);

/**
 * Reset state
 */
export const resetState = createAction(
  '[Reservation Details Invoices v2] Reset State',
);
