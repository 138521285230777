import {
  ReservationCancellationPolicy,
  ReservationCancellationPolicyDay,
  ReservationCancellationPolicyExtra,
  ReservationCancellationPolicyTotals,
} from '../../models';

export interface State {
  days: ReservationCancellationPolicyDay[];
  policy: ReservationCancellationPolicy;
  totals: ReservationCancellationPolicyTotals;
  extras: ReservationCancellationPolicyExtra[];
  loading: boolean;
  extrasLoading: boolean;
  error: any;
}

export const initialState: State = {
  days: null,
  policy: null,
  totals: null,
  extras: null,
  loading: false,
  extrasLoading: false,
  error: null,
};
