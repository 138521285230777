import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationDetailsInvoicesStoreEffects } from './effects';
import { reservationDetailsInvoicesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'reservationDetailsInvoicesStore',
      reservationDetailsInvoicesReducer,
    ),
    EffectsModule.forFeature([ReservationDetailsInvoicesStoreEffects]),
  ],
})
export class ReservationDetailsInvoicesStoreModule {}
