import { Action, createReducer, on } from '@ngrx/store';

import { InvoicePrintSetting } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { invoicePrintSetting, invoiceId }) => ({
    ...state,
    data: {
      ...state.data,
      [invoiceId]: invoicePrintSetting,
    },
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.saveRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.saveSuccess,
    (state, { invoicePrintSetting: { invoice_id, ...data } }) => ({
      ...state,
      data: {
        ...state.data,
        [invoice_id]: data as InvoicePrintSetting,
      },
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.saveFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function invoicePrintSettingsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
