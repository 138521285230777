import { createAction, props } from '@ngrx/store';

import { InvoiceDetails, Reservation } from '../../models';

export const loadReservationInformationsRequest = createAction(
  '[Moved Bill Informations] Load Reservation Informations Request',
  props<{ reservationId: number; reservationAccommodationId: number }>(),
);
export const loadReservationInformationsSuccess = createAction(
  '[Moved Bill Informations] Load Reservation Informations Success',
  props<{
    reservation: Reservation;
    reservationAccommodationId: number;
  }>(),
);
export const loadReservationInformationsFailure = createAction(
  '[Moved Bill Informations] Load Reservation Informations Failure',
  props<{ error: any }>(),
);

export const loadInvoiceInformationsRequest = createAction(
  '[Moved Bill Informations] Load Invoice Informations Request',
  props<{ invoiceId: number }>(),
);
export const loadInvoiceInformationsSuccess = createAction(
  '[Moved Bill Informations] Load Invoice Informations Success',
  props<{ invoice: InvoiceDetails }>(),
);
export const loadInvoiceInformationsFailure = createAction(
  '[Moved Bill Informations] Load Invoice Informations Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Moved Bill Informations] Load Invoice Informations Failure',
);
