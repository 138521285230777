import { MovedBillInformations } from '../../models';

export interface State {
  informations: MovedBillInformations;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  informations: null,
  isLoading: false,
  error: null,
};
