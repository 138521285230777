import { createAction, props } from '@ngrx/store';

import {
  CreateGuestCardRequest,
  DeleteGuestCardRequest,
  GuestCard,
  GuestCardLoadRequestAction,
  GuestCardsLoadRequestAction,
  UpdateGuestCardRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Guest Cards] Load Request',
  props<{
    data: GuestCardsLoadRequestAction;
  }>(),
);

export const loadSuccess = createAction(
  '[Guest Cards] Load Success',
  props<{ data: GuestCard[] }>(),
);

export const loadFailure = createAction(
  '[Guest Cards] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Guest Cards] Load Details Request',
  props<{
    request: GuestCardLoadRequestAction;
  }>(),
);

export const loadDetailsSuccess = createAction(
  '[Guest Cards] Load Details Success',
  props<{ data: GuestCard }>(),
);

export const loadDetailsFailure = createAction(
  '[Guest Cards] Load Details Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Guest Cards] Create Request',
  props<{
    request: CreateGuestCardRequest;
    onSuccess: () => void;
  }>(),
);

export const createSuccess = createAction(
  '[Guest Cards] Create Success',
  props<{ data: GuestCard[] }>(),
);

export const createFailure = createAction(
  '[Guest Cards] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Guest Cards] Update Request',
  props<{
    request: UpdateGuestCardRequest;
    onSuccess: () => void;
  }>(),
);

export const updateSuccess = createAction(
  '[Guest Cards] Update Success',
  props<{ data: GuestCard }>(),
);

export const updateFailure = createAction(
  '[Guest Cards] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Guest Cards] Delete Request',
  props<DeleteGuestCardRequest>(),
);

export const deleteSuccess = createAction(
  '[Guest Cards] Delete Success',
  props<{ id: number }>(),
);

export const deleteFailure = createAction(
  '[Guest Cards] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Guest Cards] Reset State');
