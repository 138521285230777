import {
  Invoice,
  PaymentRow,
  ReservationPaymentsList,
  ReservationPaymentsStats,
} from '../../models';

export interface State {
  payments: ReservationPaymentsList<PaymentRow>;
  invoices: ReservationPaymentsList<Invoice>;
  stats: ReservationPaymentsStats;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  payments: null,
  invoices: null,
  stats: null,
  loading: false,
  error: null,
};
