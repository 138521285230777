import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>(
  'reservationDetailsPaymentsStore',
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);

export const selectStats = createSelector(
  selectState,
  (state: State) => state.stats,
);

export const selectPayments = createSelector(
  selectState,
  (state: State) => state.payments,
);

export const selectInvoices = createSelector(
  selectState,
  (state: State) => state.invoices,
);
