import { createAction, props } from '@ngrx/store';
import { GuestCardType } from '../../models';

export const loadRequest = createAction(
  '[Guest Cards Types] Load Request',
  props<{
    externalServiceId: number;
    propertyId: number;
  }>(),
);

export const loadSuccess = createAction(
  '[Guest Cards Types] Load Success',
  props<{ data: GuestCardType[] }>(),
);

export const loadFailure = createAction(
  '[Guest Cards Types] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Guest Cards Types] Reset State');
