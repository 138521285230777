import { createAction, props } from '@ngrx/store';

import {
  Invoice,
  IPaymentData,
  RestorePaymentRequest,
  TransferPaymentRequest,
} from '../../models';

import { Hooks } from '../../helpers';

export const loadRequest = createAction(
  '[Reservation Details Payments] Load Request',
  props<{ reservationId: number; reservationAccommodationIds: number[] }>(),
);
export const loadSuccess = createAction(
  '[Reservation Details Payments] Load Success',
  props<{ paymentsData: IPaymentData; invoices: Invoice[] }>(),
);
export const loadFailure = createAction(
  '[Reservation Details Payments] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Reservation Details Payments] Reset State',
);

export const transferRequest = createAction(
  '[Reservation Details Payments] Transfer Request',
  props<TransferPaymentRequest & { hooks?: Hooks }>(),
);

export const transferSuccess = createAction(
  '[Reservation Details Payments] Transfer Success',
);

export const transferFailure = createAction(
  '[Reservation Details Payments] Transfer Failure',
  props<{ error: any }>(),
);

export const restorePaymentRequest = createAction(
  '[Reservation Details Payments] Restore Payment Request',
  props<RestorePaymentRequest & { hooks?: Hooks }>(),
);

export const restorePaymentSuccess = createAction(
  '[Reservation Details Payments] Restore Payment Success',
);

export const restorePaymentFailure = createAction(
  '[Reservation Details Payments] Restore Payment Failure',
  props<{ error: any }>(),
);
