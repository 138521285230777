import { Invoice } from '../../models';

export interface State {
  error: any;
  invoices: Invoice[];
  isLoading: boolean;
}

export const initialState: State = {
  error: null,
  invoices: null,
  isLoading: false,
};
