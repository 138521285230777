import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ReservationCancellationPolicyExtra } from '../../models';
import { ReservationCancellationPolicyService } from '../../services';

import * as fromActions from './actions';
import * as fromState from './state';

@Injectable()
export class ReservationDetailsCancellationPolicyStoreEffects {
  constructor(
    private dataService: ReservationCancellationPolicyService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCalendarRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.loadCalendar(payload).pipe(
          map(({ data }: IResponseSuccess<Partial<fromState.State>>) => {
            return fromActions.loadCalendarSuccess({ data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadCalendarFailure(error));
          }),
        ),
      ),
    ),
  );

  loadExtras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadExtrasRequest),
      switchMap(({ reservationId, days, reservationAccommodationIds }) =>
        this.dataService
          .loadExtras(reservationId, days, reservationAccommodationIds)
          .pipe(
            map(
              ({
                data: extras,
              }: IResponseSuccess<ReservationCancellationPolicyExtra[]>) => {
                return fromActions.loadExtrasSuccess({ extras });
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadExtrasFailure(error));
            }),
          ),
      ),
    ),
  );
}
