import { merge } from 'lodash';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        data: items,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.UPDATE_SUCCESS: {
      const { items } = action.payload;
      const data = { ...state.data };
      const newData = merge({}, { ...data }, items);
      return {
        ...state,
        isLoading: false,
        error: null,
        data: { ...newData },
      };
    }

    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        data: { ...state.data },
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
