import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IBillsComputation, IRegisterPayment } from '../models';

@Injectable({ providedIn: 'root' })
export class ReservationDetailsInvoicesService {
  constructor(private http: HttpClient) {}

  load(
    reservation_id: number,
    reservation_accommodation_id: number[],
    skip_rejected_advances: 0 | 1,
  ) {
    const params = generateSearchQuery({
      reservation_accommodation_id,
      skip_rejected_advances: skip_rejected_advances ? 1 : 0,
    });

    return this.http.get(
      `reservation/${reservation_id}/invoices${params ? '?' + params : ''}`,
    );
  }

  registerPayment(data: IRegisterPayment) {
    return this.http.post(
      `reservation/${data.reservation_id}/invoice/${data.reservation_invoice_id}/payment/${data.reservation_payment_id}`,
      data,
    );
  }

  generateBills(data: IBillsComputation) {
    return this.http.post(
      `reservation/${data.reservation_id}/bills_attribution`,
      data,
    );
  }
}
