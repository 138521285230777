import { InvoicePrintSetting } from '../../models';

export interface State {
  data: {
    [invoiceId: number]: InvoicePrintSetting;
  };
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: {},
  isLoading: false,
  error: null,
};
