import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { InvoicePrintSettingsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class InvoicePrintSettingsStoreEffects {
  constructor(
    private dataService: InvoicePrintSettingsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ invoiceId }) =>
        this.dataService.load(invoiceId).pipe(
          map((response) =>
            fromActions.loadSuccess({
              invoiceId,
              invoicePrintSetting: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveRequest),
      switchMap(({ invoicePrintSetting }) =>
        this.dataService.save(invoicePrintSetting).pipe(
          map(() => {
            return fromActions.saveSuccess({ invoicePrintSetting });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.saveFailure(error));
          }),
        ),
      ),
    ),
  );
}
