import { ISubdivisionAccounts } from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Subdivision Accounts] Load Request',
  LOAD_FAILURE = '[Subdivision Accounts] Load Failure',
  LOAD_SUCCESS = '[Subdivision Accounts] Load Success',

  UPDATE_REQUEST = '[Subdivision Accounts] Update Request',
  UPDATE_FAILURE = '[Subdivision Accounts] Update Failure',
  UPDATE_SUCCESS = '[Subdivision Accounts] Update Success',

  RESET_STATE = '[Subdivision Accounts] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { reservation_id: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: {
      items: ISubdivisionAccounts;
    },
  ) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      reservation_id: number;
      data: any;
      onSuccess?: () => void;
      onFailure?: () => void;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(
    public payload: {
      items: ISubdivisionAccounts;
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | ResetSuccessAction;
