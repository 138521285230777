import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GuestCard, GuestCardType } from '../../models';

export const featureAdapter: EntityAdapter<GuestCardType> =
  createEntityAdapter<GuestCardType>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<GuestCardType> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
