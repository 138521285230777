import { ISubdivisionAccounts } from '@app/models/objects/subdivision-accounts';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getData = (state: State): ISubdivisionAccounts => state.data;

export const selectSubdivisionAccountsState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('subdivisionAccounts');

export const selectReservationsError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectSubdivisionAccountsState,
  getError,
);

export const selectReservationsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectSubdivisionAccountsState,
  getIsLoading,
);
export const selectSubdivisionAccount: MemoizedSelector<
  object,
  any
> = createSelector(
  selectSubdivisionAccountsState,
  getData,
);
