import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  /**
   * Reservation invoices load reducers
   */
  on(fromActions.loadRequest, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(fromActions.loadSuccess, (state, { data }) => ({
    ...state,
    invoices: (data || []).map((invoice) => ({
      ...invoice,
      last_load: new Date(),
    })),
    isLoading: false,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  /**
   * Genera conti reducer
   */

  on(fromActions.generateBillsRequest, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(fromActions.generateBillsSuccess, (state) => ({
    ...state,
    error: null,
    isLoading: false,
  })),
  on(fromActions.generateBillsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  /**
   * Associa/Dissocia un pagamento reducer
   */

  on(fromActions.registerPaymentRequest, (state) => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  }),
  on(fromActions.registerPaymentSuccess, (state) => {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  }),
  on(fromActions.registerPaymentFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  /**
   * Reservation details bills reducers
   */
  on(fromActions.resetState, () => fromState.initialState),
);

export function reservationDetailsInvoicesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
