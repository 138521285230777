import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { InvoiceDetails, Reservation } from '../../models';
import { InvoicesService } from '../../services/invoices.service';
import { ReservationDetailsService } from '../../services/reservation-details.service';

import * as fromActions from './actions';

@Injectable()
export class MovedBillInformationsStoreEffects {
  constructor(
    private reservationService: ReservationDetailsService,
    private invoiceService: InvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationInformationsRequest),
      switchMap(({ reservationId, reservationAccommodationId }) =>
        this.reservationService.load(reservationId).pipe(
          map(({ data }: IResponseSuccess<Reservation[]>) =>
            fromActions.loadReservationInformationsSuccess({
              reservation: data[0],
              reservationAccommodationId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadReservationInformationsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadInvoiceInformationsRequest),
      switchMap(({ invoiceId }) =>
        this.invoiceService.loadDetail(invoiceId).pipe(
          map(({ data }: IResponseSuccess<InvoiceDetails[]>) =>
            fromActions.loadInvoiceInformationsSuccess({
              invoice: data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadInvoiceInformationsFailure(error));
          }),
        ),
      ),
    ),
  );
}
