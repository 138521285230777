import { createAction, props } from '@ngrx/store';

import { InvoicePrintSetting } from '../../models';

export const loadRequest = createAction(
  '[Invoice Print Settings] Load Request',
  props<{ invoiceId: number }>(),
);

export const loadSuccess = createAction(
  '[Invoice Print Settings] Load Success',
  props<{ invoiceId: number; invoicePrintSetting: InvoicePrintSetting }>(),
);

export const loadFailure = createAction(
  '[Invoice Print Settings] Load Failure',
  props<{ error: any }>(),
);

export const saveRequest = createAction(
  '[Invoice Print Settings] Save Request',
  props<{ invoicePrintSetting: InvoicePrintSetting }>(),
);

export const saveSuccess = createAction(
  '[Invoice Print Settings] Save Success',
  props<{
    invoicePrintSetting: InvoicePrintSetting;
  }>(),
);

export const saveFailure = createAction(
  '[Invoice Print Settings] Save Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Invoice Print Settings] Reset State');
